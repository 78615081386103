$mdc-theme-primary: #10abe4;
$mdc-theme-secondary: #0b78a0;
$mdc-theme-hint: #ffffff;
$mdc-theme-on-primary: #ffffff;
$mdc-theme-on-secondary: #ffffff;
$mdc-theme-on-hint: #333333;

@import "@material/theme/mdc-theme";
@import "@material/theme/color-palette";
@import "@material/textfield/mdc-text-field";
@import "@material/elevation/mdc-elevation";
@import "@material/dialog/mdc-dialog";
@import "@material/list/mdc-list";
@import "@material/switch/mdc-switch";
@import "@material/form-field/mdc-form-field";
@import "@material/floating-label/mdc-floating-label";
@import "@material/notched-outline/mdc-notched-outline";
@import "@material/line-ripple/mdc-line-ripple";
@import "@material/menu/mdc-menu";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/data-table/mdc-data-table";

@import "@material/react-button/index.scss";
@import "@material/react-dialog/index.scss";
@import "@material/react-drawer/index.scss";
@import "@material/react-floating-label/index.scss";
@import "@material/react-list/index.scss";
@import "@material/react-material-icon/index.scss";
@import "@material/react-tab-bar/index.scss";
@import "@material/react-tab-indicator/index.scss";
@import "@material/react-tab-scroller/index.scss";
@import "@material/react-tab/index.scss";
@import "@material/react-text-field/index.scss";
@import "@material/react-top-app-bar/index.scss";
@import "@material/react-fab/index.scss";
@import "@material/react-card/index.scss";
@import "@material/react-linear-progress/index.scss";
@import "@material/react-icon-button/index.scss";
@import "@material/react-switch/index.scss";
@import "@material/react-layout-grid/index.scss";
@import "@material/react-checkbox/index.scss";
@import "@material/react-typography/index.scss";

$mdc-theme-success: $material-color-green-500;

.mdc-fab--absolute {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10000;
}

.mdc-dialog {
  z-index: 10000;
}

.mdc-drawer--dismissible {
  top: auto;
}

.mdc-button--reset {
  @include mdc-button-filled-accessible($material-color-grey-300);
}

.mdc-button--danger {
  @include mdc-button-filled-accessible($mdc-theme-error);
}

html,
body {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

//#region react-split-pane
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid hsla(0, 0%, 100%, 0);
  border-right: 5px solid hsla(0, 0%, 100%, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
//#region
