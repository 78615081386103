.image {
  max-width: 100%;
}

.fullWidth {
  width: 100%;
  :global(.rmwc-data-table),
  table {
    width: 100%;
  }
}

.layoutGrid {
  padding: 0;
  min-width: 500px;
}

.textAlignRight {
  text-align: right;
}
