.fullWidth {
  width: 100%;
  :global(.rmwc-data-table),
  table {
    width: 100%;
  }
}

.promoTag {
  border-radius: 4px;
  padding: 0.6em 1.2em;
  font-weight: 500;
}

.preview {
  padding: 30px 20px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
