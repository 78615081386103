@import "~@/index.scss";

.dialog {
  @media (min-width: 1300px) {
    :global(.mdc-dialog__surface) {
      max-width: 1300px;
    }
  }
}

.listItemGrid {
  width: 100%;
  padding: 5px 10px;
  .listItemMeta {
    text-align: right;
  }
  :global(.mdc-list-item__text) {
    display: block;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list {
  overflow-y: scroll;
  min-width: 400px;
  height: 100%;
  padding-bottom: 16px;
}

.listEnabled {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dropZone {
  flex: 1;
  &.isDraggingOver {
    background: #e3fcef;
  }
}

.listDisabled {
  background: #dddddd;
  flex: 1;
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  .dropZone {
    & > * {
      opacity: 0.5;
    }
  }
}

.listItemContainer {
  padding: 8px 16px;
}
.listItem {
  display: flex;
  flex-direction: row;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}

.listItemImage {
  width: 90px;
}
.listItemText {
  flex: 1;
  padding: 16px;
}
