.color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.swatch {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
